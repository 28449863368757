import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loader from "../component/Loader";
import Calculator from "./../Calculator";
import Chart from "./../pages/testChat/Chart.js";
import OrderDashboard from "./../pages/order/Dashboard";
import SimpleDashboard from "../pages/order/SimpleDashboard";
import TradedDashboard from "../pages/order/TradedDashboard";
import PositionDashboard from "../pages/order/PositionDashboard";
import PortfolioDashboard from "../pages/order/PortfolioDashboard";
import SimpleHistory from "../pages/order/SimpleHistory";
import TradeHistory from "../pages/order/TradeHistory";
import OrderDetails from "./../pages/order/Details";
import AddOrderStrategy from "./../pages/order/AddStrategy";
import AddSimpleOrderStrategy from "./../pages/order/AddSimpleOrderStrategy";
import OrderSearch from "./../pages/order/Search";
import Subscription from "./../pages/subscription/Subscription";
import PageNotFound from "../pages/pageNotFound/PageNotFound";

import SubscriptionFeature from "./../pages/subscription/feature";

const Home = React.lazy(() => import("./../pages/home/index"));
const GlobalMarketData = React.lazy(() => import("./../GlobalMarketData"));
//import GlobalMarketData from './../pages/testChat/Chart';

const Header = React.lazy(() => import("./../layout/Header"));
const Footer = React.lazy(() => import("./../layout/Footer"));

const Company = React.lazy(() => import("./../pages/company/Index"));
const Autosignal = React.lazy(() => import("./../pages/autosignal/Index"));
const AutoMostCommon = React.lazy(() =>
  import("./../pages/autosignal/MostCommon")
);
const AutoStock = React.lazy(() => import("./../pages/autosignal/SignalStock"));
const Login = React.lazy(() => import("./../pages/page/Login"));
const Registration = React.lazy(() => import("./../pages/page/Registration"));

const Support = React.lazy(() => import("./../pages/page/Support"));

const Sector = React.lazy(() => import("./../pages/sector/Index"));
const sectorStock = React.lazy(() => import("./../pages/sector/SignalStock"));
const Industry = React.lazy(() => import("./../pages/industry/Index"));
const IndustryStock = React.lazy(() =>
  import("./../pages/industry/SignalStock")
);

const Screener = React.lazy(() => import("./../pages/screener/Index"));
const ScreenerMostCommon = React.lazy(() =>
  import("./../pages/screener/MostCommon")
);
const ScrStock = React.lazy(() => import("./../pages/screener/SignalStock"));
const Watchlist = React.lazy(() => import("./../pages/watchlist/Index"));
const WatchlistStock = React.lazy(() =>
  import("./../pages/watchlist/WatchlistStock")
);
const AddWatchlist = React.lazy(() =>
  import("./../pages/watchlist/Addwatchlist")
);
const Portfolio = React.lazy(() => import("./../pages/portfolio/Index"));
const FolioStock = React.lazy(() => import("./../pages/portfolio/FolioStock"));
const Editcolumn = React.lazy(() => import("./../component/EditColumn"));
const OTP = React.lazy(() => import("./../pages/page/Otp"));
const About = React.lazy(() => import("./../pages/page/About"));
const Whyus = React.lazy(() => import("./../pages/page/Whyus"));
const Privacy = React.lazy(() => import("./../pages/page/Privacypolicy"));
const Refund = React.lazy(() => import("./../pages/page/Refundpolicy"));
const Terms = React.lazy(() => import("./../pages/page/TersmAndCond"));
const ThankYou = React.lazy(() => import("./../pages/page/ThankYou"));
const ImportantLink = React.lazy(() => import("./../pages/page/ImportantLink"));

const HowToUse = React.lazy(() => import("./../pages/page/HowToUse"));
const AphabetSymbolList = React.lazy(() =>
  import("./../pages/page/AphabetSymbolList")
);
const Box = React.lazy(() => import("./../pages/box/Index"));
const Myaccount = React.lazy(() => import("./../pages/myaccount/index"));
const MyLearning = React.lazy(() => import("./../pages/myaccount/learning"));
const MyLearningDetails = React.lazy(() =>
  import("./../pages/myaccount/LearningDetails")
);
const Dashboard = React.lazy(() => import("./../pages/dashboard/index"));
const FreeTips = React.lazy(() => import("./../pages/tips/FreeTips"));
const PremimumTips = React.lazy(() => import("./../pages/tips/PremimumTips"));
const NiftyTips = React.lazy(() => import("./../pages/tips/NiftyTips"));
const RoboFreeTips = React.lazy(() => import("./../pages/tips/RoboFreeTips"));
const RoboPremiumTips = React.lazy(() =>
  import("./../pages/tips/RoboPremiumTips")
);
const Cash = React.lazy(() => import("./../pages/pricePattern/Cash"));
const Futurestock = React.lazy(() =>
  import("./../pages/pricePattern/Futurestock")
);
const PriceMarketActivity = React.lazy(() =>
  import("./../pages/pricePattern/PriceMarketActivity")
);
const TodayActivity = React.lazy(() =>
  import("./../pages/pricePattern/TodaysActivity")
);
const FIIDII = React.lazy(() => import("./../pages/pricePattern/FIIDII"));
const Advdecd = React.lazy(() => import("./../pages/pricePattern/Advdecd"));
const Event = React.lazy(() => import("./../pages/pricePattern/Event"));
const EventPerformance = React.lazy(() =>
  import("./../pages/pricePattern/EventPerformance")
);
const CorporateAction = React.lazy(() =>
  import("./../pages/pricePattern/CorporateAction")
);
const NewFiftyTwo = React.lazy(() =>
  import("./../pages/pricePattern/NewFiftyTwo")
);
const Blockdeals = React.lazy(() =>
  import("./../pages/pricePattern/Blockdeals")
);
const BlockdealsPerformance = React.lazy(() =>
  import("./../pages/pricePattern/BlockdealsPerformance")
);
const Bulkdeals = React.lazy(() => import("./../pages/pricePattern/Bulkdeals"));
const BulkdealsPerformance = React.lazy(() =>
  import("./../pages/pricePattern/BulkdealsPerformance")
);
const Sectoranalysis = React.lazy(() =>
  import("./../pages/pricePattern/Sectoranalysis")
);
const SectoranalysisData = React.lazy(() =>
  import("./../pages/pricePattern/SectoranalysisData")
);
const PullCallRatioData = React.lazy(() =>
  import("./../pages/pricePattern/PullCallRatioData")
);
const IndiaVix = React.lazy(() => import("./../pages/pricePattern/IndiaVix"));
const Marketbreadth = React.lazy(() =>
  import("./../pages/pricePattern/Marketbreadth")
);
const MarketbreadthStock = React.lazy(() =>
  import("./../pages/pricePattern/MarketbreadthStock")
);
const Derivative = React.lazy(() =>
  import("./../pages/pricePattern/Derivative")
);
const Fiifno = React.lazy(() => import("./../pages/pricePattern/Fiifno"));

const Test = React.lazy(() => import("./../Test"));

const PremiumServicesList = React.lazy(() =>
  import("./../pages/page/PremiumServicesList")
);
const PremiumServicesPage = React.lazy(() =>
  import("./../pages/page/PremiumServicesPage")
);
const SpecialScreener = React.lazy(() =>
  import("./../pages/premiumService/SpecialScreener")
);
const Stratergy = React.lazy(() =>
  import("./../pages/premiumService/Strategy/Stratergy")
);
const AddStratergy = React.lazy(() =>
  import("./../pages/premiumService/Strategy/AddStrategies")
);
const StratergyStock = React.lazy(() =>
  import("./../pages/premiumService/Strategy/StratergyStock")
);
const StratergyPerFromance = React.lazy(() =>
  import("./../pages/premiumService/Strategy/Performance")
);
const Backtest = React.lazy(() => import("./../pages/backTest/Backtest"));
const StockIdea = React.lazy(() =>
  import("./../pages/premiumService/StockIdea")
);
const StockIdeaDetail = React.lazy(() =>
  import("./../pages/premiumService/StockIdeaDetails")
);
const SupportAndTarget = React.lazy(() =>
  import("./../pages/premiumService/SupportAndTarget")
);
const TradingTools = React.lazy(() =>
  import("./../pages/premiumService/TradingTools")
);
const TradingToolsPerformance = React.lazy(() =>
  import("./../pages/premiumService/TradingToolsPerformance")
);
const PreMarket = React.lazy(() =>
  import("./../pages/premiumService/PreMarket")
);
const OpenInterest = React.lazy(() =>
  import("./../pages/premiumService/OpenInterest")
);
const MarketSignal = React.lazy(() =>
  import("./../pages/premiumService/MarketSignal")
);
const WatchlistSignal = React.lazy(() =>
  import("./../pages/premiumService/WatchlistSignal")
);
// const ChartAndPrice = React.lazy(() =>
//   import("./../pages/premiumService/ChartAndPrice")
// );
const SignalAlert = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/SignalAlert")
);
const AddActive = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddActive")
);
const AddFNO = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddFNO")
);
const AddWatchlistAlert = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddWatchlistAlert")
);
const Addportfolio = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/Addportfolio")
);
const AddNifty = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddNifty")
);
const AddTrading = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddTrading")
);
const AddStrategies = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddStrategies")
);
const AddCommodity = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddCommodity")
);
const AddAutosignal = React.lazy(() =>
  import("./../pages/premiumService/SignalAlert/AddAutosignal")
);
const HourlyGainerLooser = React.lazy(() =>
  import("./../pages/premiumService/HourlyGainerLooser")
);

const DemoTable = React.lazy(() => import("./../pages/home/demo_table"));

const Tradingterminal = React.lazy(() =>
  import("./../pages/tradingterminal/Index")
);
const Market = React.lazy(() => import("./../pages/market/Index"));
const Optionchain = React.lazy(() => import("./../pages/optionchain/Index"));
const OptionchainAnalysis = React.lazy(() =>
  import("./../pages/optionchain/Analysis")
);

const Commodity = React.lazy(() => import("./../pages/commodity/Index"));
const CommoditySignal = React.lazy(() => import("./../pages/commodity/Signal"));
const CommoditySignalDetails = React.lazy(() =>
  import("./../pages/commodity/Details")
);

const portfolioAnalysis = React.lazy(() =>
  import("./../pages/portfolioAnalysis/Index")
);

const portfolioAnalysisProfile = React.lazy(() =>
  import("./../pages/portfolioAnalysis/Profile")
);
const portfolioAnalysisAddProfile = React.lazy(() =>
  import("./../pages/portfolioAnalysis/AddProfile")
);
const portfolioProfileData = React.lazy(() =>
  import("./../pages/portfolioAnalysis/ProfileDataListing")
);
const portfolioAnalysisPerformance = React.lazy(() =>
  import("./../pages/portfolioAnalysis/Performance")
);

const OptionDataHistorical = React.lazy(() =>
  import("./../pages/optionchain/OptionDataHistorical")
);
const OptionDashboard = React.lazy(() =>
  import("./../pages/optionchain/OptionDashboard")
);
const HeightestOIHistory = React.lazy(() =>
  import("./../pages/optionchain/HeightestOIHistory")
);
const OptionFutireDashboard = React.lazy(() =>
  import("./../pages/optionchain/FutureDashbaord/Dashboard")
);
const OptionTradingTerminal = React.lazy(() =>
  import("./../pages/optionchain/OptionTradingTerminal")
);
const OptionEditcolumn = React.lazy(() =>
  import("./../pages/optionchain/EditOptionColumn")
);
const SupportAndTargets = React.lazy(() =>
  import("./../pages/optionchain/SupportAndTarget")
);

const OptionStrategyData = React.lazy(() =>
  import("./../pages/optionStrategies/index")
);

const OptionStrategyDetails = React.lazy(() =>
  import("./../pages/optionStrategies/details")
);

const OptionTips = React.lazy(() =>
  import("./../pages/optionchain/OptionTips")
);
const OptionTipsLive = React.lazy(() =>
  import("./../pages/optionchain/OptionTipsLive")
);
const MarketDetails = React.lazy(() =>
  import("./../pages/marketDetails/Index")
);
const FAQ = React.lazy(() => import("./../pages/page/faq"));
const Articles = React.lazy(() => import("./../pages/articles/Index"));
const ArticlesDetails = React.lazy(() => import("./../pages/articles/Details"));
const HeatMapComponent = React.lazy(() => import("./../pages/heatMap/index"));

const FundamentalScreener = React.lazy(() =>
  import("./../pages/fundamentalScreener/Index")
);
const FundamentalScreenerStock = React.lazy(() =>
  import("./../pages/fundamentalScreener/SignalStock")
);

const IndicatorsScreener = React.lazy(() =>
  import("./../pages/indicatorsScreener/Index")
);
const IndicatorsScreenerStock = React.lazy(() =>
  import("./../pages/indicatorsScreener/SignalStock")
);
const customizeScreener = React.lazy(() =>
  import("./../pages/customizeScreener/Index")
);

const customizeScreenerStock = React.lazy(() =>
  import("./../pages/customizeScreener/SignalStock")
);

const PreMarketScreener = React.lazy(() =>
  import("./../pages/preMarketScreener/Index")
);

const PreMarketScreenerStock = React.lazy(() =>
  import("./../pages/preMarketScreener/SignalStock")
);

const PreMarketStockDetails = React.lazy(() =>
  import("./../pages/preMarketScreener/StockDetails")
);

const OptionScreener = React.lazy(() =>
  import("./../pages/optionScreener/Index")
);
const PMS = React.lazy(() => import("./../pages/enquiryForm/PMS"));
const IntrinsicValueCalculator = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/index")
);

const DividendDiscountModel = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/DividendDiscountModel")
);
const IntrinsicDashboard = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/Dashboard")
);
const DiscountedCashFLow = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/DiscountedCashFLow")
);
const GrahamCalculator = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/GrahamCalculator")
);
const AdvancedGrahamCalculator = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/AdvancedGrahamCalculator")
);

const ReturnOnEquity = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/ReturnOnEquity")
);

const PriceEarningsValuation = React.lazy(() =>
  import("./../pages/IntrinsicValueCalculator/PriceEarningsValuation")
);
const High52Data = React.lazy(() =>
  import("./../pages/marketMovers/High52Data")
);
const Low52Data = React.lazy(() => import("./../pages/marketMovers/Low52Data"));
const HighGainerData = React.lazy(() =>
  import("./../pages/marketMovers/HighGainerData")
);
const HighLoserData = React.lazy(() =>
  import("./../pages/marketMovers/HighLoserData")
);

const DeliveryBreakout = React.lazy(() =>
  import("./../pages/marketMovers/DeliveryBreakout")
);
const AllTimeHighStocks = React.lazy(() =>
  import("./../pages/marketMovers/AllTimeHighStocks")
);

const VideoCources = React.lazy(() => import("./../pages/videoCources/index"));

const CourseDetails = React.lazy(() =>
  import("./../pages/videoCources/courseDetails")
);

const CourseThankyou = React.lazy(() =>
  import("./../pages/videoCources/CourseThankyou")
);
const Insider = React.lazy(() => import("./../pages/pricePattern/Insider"));

const MWDATAList = React.lazy(() =>
  import("./../pages/pricePattern/MWDATAList")
);

const MWETFDetail = React.lazy(() =>
  import("./../pages/pricePattern/MWETFDetail")
);

const BasicsOfMarket = React.lazy(() =>
  import("./../pages/basicsOfStockMarket/index")
);

const FreeWebinar = React.lazy(() =>
  import("./../pages/enquiryForm/FreeWebinar")
);
const FreeWebinarThanks = React.lazy(() =>
  import("./../pages/enquiryForm/WebinarThank")
);
const TraderCommunity = React.lazy(() =>
  import("./../pages/TraderCommunity/Index")
);
const TraderCommunityDetail = React.lazy(() =>
  import("./../pages/TraderCommunity/TraderDetail")
);
const PiotroskiScan = React.lazy(() =>
  import("./../pages/piotroski-scan/Index")
);

const MultiChart = React.lazy(() => import("./../pages/Chart/Index"));

export default class MyRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { show_system_error: true };
  }
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route path="/piotroski-scan" component={PiotroskiScan} exact />
            <Route path="/multi-chart" component={MultiChart} exact />
            <Route
              path="/basics-of-stock-market"
              component={BasicsOfMarket}
              exact
            />
            <Route path="/trader-community" component={TraderCommunity} exact />
            <Route
              path="/trader-community/:traderSlug"
              component={TraderCommunityDetail}
              exact
            />
            <Route path="/free-webinar" component={FreeWebinar} exact />
            <Route
              path="/free-webinar/thank-you"
              component={FreeWebinarThanks}
              exact
            />
            <Route path="/insider-trading" component={Insider} exact />
            <Route path="/exchange-traded-funds" component={MWDATAList} exact />
            <Route
              path="/exchange-traded-funds/:symbol"
              component={MWETFDetail}
              exact
            />
            <Route path="/video-course" component={VideoCources} exact />
            <Route
              path="/video-course/thank-you"
              component={CourseThankyou}
              exact
            />
            <Route
              path="/video-course/:courseSlug"
              component={CourseDetails}
              exact
            />
            <Route path="/test-chart" component={Chart} exact />
            <Route path="/52-week-high-stocks" component={High52Data} exact />
            <Route path="/52-week-low-stocks" component={Low52Data} exact />
            <Route path="/top-gainers-nse" component={HighGainerData} exact />
            <Route path="/top-losers-nse" component={HighLoserData} exact />
            <Route path="/breakout-stocks" component={DeliveryBreakout} exact />
            <Route
              path="/market/all-time-high-stocks"
              component={AllTimeHighStocks}
              exact
            />
            <Route
              path="/intrinsic-value"
              component={IntrinsicValueCalculator}
              exact
            />
            <Route
              path="/price-earnings-valuation-calculator"
              component={PriceEarningsValuation}
              exact
            />
            <Route
              path="/return-on-equity-calculator"
              component={ReturnOnEquity}
              exact
            />
            <Route
              path="/graham-number-calculator"
              component={GrahamCalculator}
              exact
            />
            <Route
              path="/advanced-graham-number-calculator"
              component={AdvancedGrahamCalculator}
              exact
            />
            <Route
              path="/intrinsic-value-calculator"
              component={IntrinsicDashboard}
              exact
            />
            <Route
              path="/dividend-cash-flow-model"
              component={DiscountedCashFLow}
              exact
            />
            <Route
              path="/dividend-discount-model"
              component={DividendDiscountModel}
              exact
            />
            <Route path="/pms" component={PMS} exact />
            <Route path="/option-screener" component={OptionScreener} exact />
            <Route
              path="/intraday-screener"
              component={customizeScreener}
              exact
            />
            <Route
              path="/intraday-screener-stock"
              component={customizeScreenerStock}
              exact
            />
            <Route
              path="/preMarket-stock/:symbol"
              component={PreMarketStockDetails}
              exact
            />
            <Route
              path="/preMarket-screener"
              component={PreMarketScreener}
              exact
            />
            <Route
              path="/preMarket-screener/:screener_id/:sector_id"
              component={PreMarketScreenerStock}
              exact
            />
            <Route
              path="/fundamental-analysis"
              component={FundamentalScreener}
              exact
            />
            <Route
              path="/fundamental-analysis/:screener_id/:sector_id"
              component={FundamentalScreenerStock}
              exact
            />
            <Route
              path="/technical-analysis"
              component={IndicatorsScreener}
              exact
            />
            <Route
              path="/technical-analysis/:screener_id/:sector_id"
              component={IndicatorsScreenerStock}
              exact
            />
            <Route
              path="/option-chain/option-strategy"
              component={OptionStrategyData}
              exact
            />
            <Route
              path="/option-chain/option-tips"
              component={OptionTips}
              exact
            />
            <Route
              path="/option-chain/live-option-tips"
              component={OptionTipsLive}
              exact
            />
            <Route
              path="/option-chain/option-strategy/:strategyId"
              component={OptionStrategyDetails}
              exact
            />
            <Route path="/nifty-heatmap" component={HeatMapComponent} exact />
            <Route path="/faq" component={FAQ} exact />
            <Route path="/investing" component={Articles} exact />
            <Route
              path="/investing/:article_slug"
              component={ArticlesDetails}
              exact
            />
            <Route
              path="/option-chain/edit-column"
              component={OptionEditcolumn}
              exact
            />
            <Route
              path="/option-data-historical"
              component={OptionDataHistorical}
              exact
            />
            <Route path="/option-dashboard" component={OptionDashboard} exact />
            <Route
              path="/nse-option-chain-data"
              component={Optionchain}
              exact
            />
            <Route
              path="/option-chain/analysis"
              component={OptionchainAnalysis}
              exact
            />
            <Route path="/Calculator" component={Calculator} exact />
            <Route
              path="/option-chain/highest-oi-history"
              component={HeightestOIHistory}
              exact
            />
            <Route
              path="/option-chain/option-trading-terminal"
              component={OptionTradingTerminal}
              exact
            />
            <Route
              path="/option-chain/support-and-target"
              component={SupportAndTargets}
              exact
            />
            <Route path="/important-link" component={ImportantLink} exact />
            <Route path="/order-dashboard" component={OrderDashboard} exact />
            <Route
              path="/simple-order-dashboard"
              component={SimpleDashboard}
              exact
            />
            <Route
              path="/trade-order-dashboard"
              component={TradedDashboard}
              exact
            />
            <Route
              path="/position-order-dashboard"
              component={PositionDashboard}
              exact
            />
            <Route
              path="/portfolio-order-dashboard"
              component={PortfolioDashboard}
              exact
            />
            <Route path="/trade-order-history" component={TradeHistory} exact />
            <Route
              path="/simple-order-history"
              component={SimpleHistory}
              exact
            />
            <Route
              path="/order-dashboard/add-strategy"
              component={AddOrderStrategy}
              exact
            />
            <Route
              path="/order-dashboard/edit-strategy/:order_id"
              component={AddOrderStrategy}
              exact
            />
            <Route
              path="/order-dashboard/add-simple-strategy"
              component={AddSimpleOrderStrategy}
              exact
            />
            <Route
              path="/order-dashboard/edit-simple-strategy/:order_id"
              component={AddSimpleOrderStrategy}
              exact
            />
            <Route
              path="/order-dashboard/:order_id"
              component={OrderDetails}
              exact
            />
            <Route
              path="/order-dashboard/search"
              component={OrderSearch}
              exact
            />
            <Route
              path="/futures-and-options"
              component={OptionFutireDashboard}
              exact
            />
            <Route
              path="/portfolio-analysis"
              component={portfolioAnalysis}
              exact
            />
            <Route
              path="/portfolio-analysis/profile"
              component={portfolioAnalysisProfile}
              exact
            />
            <Route
              path="/portfolio-analysis/profile-data/:profileID"
              component={portfolioProfileData}
              exact
            />
            <Route
              path="/portfolio-analysis/add-profile"
              component={portfolioAnalysisAddProfile}
              exact
            />
            <Route path="/commodity" component={Commodity} exact />
            <Route path="/commodity-signal" component={CommoditySignal} exact />
            <Route
              path="/commodity-details/:commoditySignal_id"
              component={CommoditySignalDetails}
              exact
            />
            <Route path="/trading-terminal" component={Tradingterminal} exact />
            <Route path="/premium" component={PremiumServicesPage} exact />
            <Route
              path="/premium-services"
              component={PremiumServicesList}
              exact
            />
            <Route
              path="/premium-services/:slug"
              component={PremiumServicesList}
              exact
            />
            <Route
              path="/hourly-gainer-looser"
              component={HourlyGainerLooser}
              exact
            />
            <Route path="/special-screener" component={SpecialScreener} exact />
            <Route path="/stratergy" component={Stratergy} exact />
            <Route
              path="/stratergy/performance"
              component={StratergyPerFromance}
              exact
            />
            <Route path="/add-stratergy" component={AddStratergy} exact />
            <Route
              path="/edit-stratergy/:strategies_id"
              component={AddStratergy}
              exact
            />
            <Route
              path="/stratergy-stock/:strategies_id"
              component={StratergyStock}
              exact
            />
            <Route path="/symbols/" component={AphabetSymbolList} exact />
            <Route
              path="/symbols/:character"
              component={AphabetSymbolList}
              exact
            />
            <Route path="/back-testing" component={Backtest} exact />
            <Route path="/stock-idea" component={StockIdea} exact />
            <Route
              path="/stock-idea/:ideaid"
              component={StockIdeaDetail}
              exact
            />
            <Route
              path="/support-and-target"
              component={SupportAndTarget}
              exact
            />
            <Route path="/trading-tools" component={TradingTools} exact />
            <Route
              path="/trading-tools/performance"
              component={TradingToolsPerformance}
              exact
            />
            <Route
              path="/portfolio-analysis/performance"
              component={portfolioAnalysisPerformance}
              exact
            />
            <Route path="/nse-pre-market" component={PreMarket} exact />
            <Route
              path="/open-interest-analysis"
              component={OpenInterest}
              exact
            />
            <Route path="/market-signal" component={MarketSignal} exact />
            <Route path="/watchlist-signal" component={WatchlistSignal} exact />
            <Route path="/chart-and-price" component={MultiChart} exact />
            <Route path="/signal-alert" component={SignalAlert} exact />
            <Route path="/add-active-alert" component={AddActive} exact />
            <Route path="/add-fno-alert" component={AddFNO} exact />
            <Route
              path="/add-watchlist-alert"
              component={AddWatchlistAlert}
              exact
            />
            <Route path="/add-portfolio-alert" component={Addportfolio} exact />
            <Route path="/add-nifty-alert" component={AddNifty} exact />
            <Route path="/add-trading-alert" component={AddTrading} exact />
            <Route
              path="/add-strategies-alert"
              component={AddStrategies}
              exact
            />
            <Route path="/add-commodity-alert" component={AddCommodity} exact />
            <Route
              path="/add-autosignal-alert"
              component={AddAutosignal}
              exact
            />
            <Route path="/" component={Home} exact />
            <Route path="/subscription" component={Subscription} exact />
            <Route
              path="/subscription-feature"
              component={SubscriptionFeature}
              exact
            />
            <Route path="/support" component={Support} exact />
            <Route path="/market" component={Market} exact />
            <Route path="/global-market/" component={GlobalMarketData} exact />
            <Route path="/login/" component={Login} exact />
            <Route path="/otp/:mobile" component={OTP} exact />
            <Route path="/registration/" component={Registration} exact />
            <Route
              path="/market-Details/:symbol"
              component={MarketDetails}
              exact
            />
            <Route path="/stocks/:symbol" component={Company} exact />
            <Route path="/company/:symbol" component={Company} exact />
            <Route path="/stock-details/:symbol" component={Company} exact />
            <Route path="/autosignal/" component={Autosignal} exact />
            <Route
              path="/autosignal/most-common"
              component={AutoMostCommon}
              exact
            />
            <Route path="/screener/" component={Screener} exact />
            <Route
              path="/screener/most-common"
              component={ScreenerMostCommon}
              exact
            />
            <Route path="/nse-sectors-list/" component={Sector} exact />
            <Route
              path="/nse-sectors-list/:sector_id/"
              component={sectorStock}
              exact
            />
            <Route path="/indian-industry-sector/" component={Industry} exact />
            <Route
              path="/indian-industry-sector/:sector_id/"
              component={IndustryStock}
              exact
            />
            <Route path="/watchlist/" component={Watchlist} exact />
            <Route path="/add-watchlist/" component={AddWatchlist} exact />
            <Route
              path="/update-watchlist/:watchlist_id"
              component={AddWatchlist}
              exact
            />
            <Route
              path="/watchlist-stock/:watchlist_id"
              component={WatchlistStock}
              exact
            />
            <Route path="/portfolio/" component={Portfolio} exact />
            <Route
              path="/portfolio-stock/:portfolio_id"
              component={FolioStock}
              exact
            />
            <Route path="/edit-column/" component={Editcolumn} exact />
            <Route path="/about/" component={About} exact />
            <Route path="/how-to-use/" component={HowToUse} exact />
            <Route path="/why-us/" component={Whyus} exact />
            <Route path="/privacy-policy/" component={Privacy} exact />
            <Route path="/refund-policy/" component={Refund} exact />
            <Route path="/terms-and-condition/" component={Terms} exact />
            <Route path="/my-account" component={Myaccount} exact />
            <Route path="/my-account/learning" component={MyLearning} exact />
            <Route
              path="/my-account/my-course/:courseid"
              component={MyLearningDetails}
              exact
            />
            <Route path="/dashboard" component={Dashboard} exact />
            <Route path="/free-stock-tips" component={FreeTips} exact />
            <Route path="/premimum-stock-tips" component={PremimumTips} exact />
            <Route
              path="/robo-free-stock-tips"
              component={RoboFreeTips}
              exact
            />
            <Route
              path="/robo-premium-stock-tips"
              component={RoboPremiumTips}
              exact
            />
            <Route path="/nifty-stock-tips" component={NiftyTips} exact />
            <Route path="/thank-you/" component={ThankYou} exact />
            <Route path="/cash" component={Cash} exact />
            <Route path="/future-stock" component={Futurestock} exact />
            <Route
              path="/stock-market-today"
              component={PriceMarketActivity}
              exact
            />
            <Route path="/fii-dii-data-nse" component={TodayActivity} exact />
            <Route path="/fii-dii-activities" component={FIIDII} exact />
            <Route
              path="/advance-decline-ratio-nse"
              component={Advdecd}
              exact
            />
            <Route path="/event" component={Event} exact />
            <Route
              path="/event-performance"
              component={EventPerformance}
              exact
            />
            <Route path="/bulk-deals-nse" component={Bulkdeals} exact />
            <Route
              path="/bulk-deals-nse-performance"
              component={BulkdealsPerformance}
              exact
            />
            <Route path="/block-deals-nse" component={Blockdeals} exact />
            <Route
              path="/block-deals-nse-performance"
              component={BlockdealsPerformance}
              exact
            />
            <Route path="/corporate-action" component={CorporateAction} exact />
            <Route path="/new-fifty-two" component={NewFiftyTwo} exact />
            <Route path="/india-vix" component={IndiaVix} exact />
            <Route
              path="/market-breadth-today"
              component={Marketbreadth}
              exact
            />
            <Route
              path="/market-breadth-stock/:ex_sector_id"
              component={MarketbreadthStock}
              exact
            />
            <Route path="/nse-derivative" component={Derivative} exact />
            <Route path="/fii-futures-data" component={Fiifno} exact />
            <Route path="/sector-analysis" component={Sectoranalysis} exact />
            <Route
              path="/sector-analysis/:ex_sector_id"
              component={SectoranalysisData}
              exact
            />
            <Route path="/put-call-ratio" component={PullCallRatioData} exact />
            <Route path="/box/" component={Box} exact />
            <Route
              path="/autosignal/:signal_id/:sector_id/:date"
              component={AutoStock}
              exact
            />
            <Route
              path="/screener/:signal_id/:sector_id/:date"
              component={ScrStock}
              exact
            />
            <Route path="/test" component={Test} exact />
            <Route path="/table-demo" component={DemoTable} exact />
            <Route exact path="/" component={Home} />
            <Route component={PageNotFound} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </Suspense>
    );
  }
}
